import React from 'react';
import background from './assets/background.jpg';
import avatar from './assets/avatar.jpg';

function App() {
  return (
    <div className='wrapper'>
      <div className="wrapper-top">
        <div className="photo-cover" style={{ backgroundImage: `url(${background})` }}>
          <div className="avatar-circle">
            <div className="avatar-container">
              <img src={avatar} alt="avatar"/>
            </div>
          </div>
        </div>
        <div className="main">
          <div className="bio-container">
            <div className="name">Alexander Peterson</div>
            <div className="title">Architecture and interiors photographer</div>
            <div className="contacts">+972587994964 | alexpetersonstudio@gmail.com</div>
          </div>
          <div className="links-container">
            <div className="links-list">
              <a className="link" href="https://www.alexanderpetersonphoto.com/" target="_blank" rel="noreferrer">Website</a>
              <a className="link" href="https://wa.me/972587994964" target="_blank" rel="noreferrer">WhatsApp</a>
              <a className="link" href="https://www.instagram.com/alexander___peterson/" target="_blank" rel="noreferrer">Instagram</a>
              <a className="link" href="https://www.behance.net/alexanderpeterson" target="_blank" rel="noreferrer">Behance</a>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-footer">
        <p className="footer-signature">Alexander Peterson © {new Date().getFullYear()}</p>
      </div>
    </div>
  );
}

export default App;